import ProductsList from "./sections/ProductsList/ProductsList";
import style from "./Catalog.module.css";
import Container from "../../components/Container/Container";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../api/api";
import { Helmet } from "react-helmet";
import { Pagination } from "@mui/material";

export default function Catalog() {
    const [categories, setCategories] = useState(null);
    const [catId, setCatId] = useState(null);
    const { t, i18n } = useTranslation();
    const [activeCat, setActiveCat] = useState("all");
    const [numberPage, setNumberPage] = useState(1);

    const choiceCat = (id, catSlug) => {
        setCatId(id);
        setActiveCat(catSlug);
        setNumberPage(1);
    };

    const getCountAllProductInCategory = () => {
        let count = 0;
        categories.forEach((element) => {
            if (element.slug === activeCat) {
                count += element.count;
            }
        });
        return Math.ceil(count / 12);
    };

    const handleChangePage = (event, value) => {
        setNumberPage(value);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        Api.getCategories(setCategories, i18n.language);
    }, [i18n.language]);

    return (
        <>
            <Helmet>
                <title>
                    Detailing Chemicals Catalog in Georgia: Explore Our
                    Professional Products - Detailing club
                </title>
                <meta
                    name="keywords"
                    content="Detailing chemicals catalog, Professional detailing products, Automotive detailing supplies in Georgia, Catalog of chemical products, Detailing club"
                />
                <meta
                    name="description"
                    content="Discover a wide range of detailing chemicals in Georgia at Detailing club. Our catalog features professional products for automotive detailing. Find the right solutions for your car care needs."
                />
            </Helmet>
            <div className={style.catalogBlock}>
                <section className={style.choiceCatSection}>
                    <Container>
                        <div className={style.mainInfo}>
                            <div className={style.catList}>
                                <h1 id="categories">
                                    {t("products.catTitle")}:
                                </h1>
                                {categories &&
                                    categories.map((cat) => {
                                        return (
                                            <button
                                                onClick={() =>
                                                    choiceCat(cat.id, cat.slug)
                                                }
                                                className={
                                                    activeCat === cat.slug
                                                        ? `${style.activeCat}`
                                                        : ""
                                                }
                                                key={cat.id}
                                            >
                                                {cat.name}
                                            </button>
                                        );
                                    })}
                            </div>
                        </div>
                    </Container>
                </section>
                <ProductsList category={catId} numberPage={numberPage} />
                <div className={style.paginateBlock}>
                    <Pagination
                        className={style.paginate}
                        count={categories ? getCountAllProductInCategory() : 0}
                        defaultPage={1}
                        siblingCount={0}
                        showFirstButton
                        showLastButton
                        color="standard"
                        size="small"
                        page={numberPage}
                        onChange={handleChangePage}
                    />
                </div>
                <button
                    href=""
                    className={style.upBtn}
                    onClick={() =>
                        window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                        })
                    }
                ></button>
            </div>
        </>
    );
}
