import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home/Home.jsx";
import Layout from "./pages/Layout/Layout.jsx";
import Catalog from "./pages/Catalog/Catalog.jsx";

import Checkout from "./pages/Checkout/Checkout.jsx";
import Feedback from "./pages/Feedback/Feedback.jsx";
import Product from "./pages/Product/Product";

import { Provider } from "react-redux";
import store from "./store/index";
import Contacts from "./pages/Contacts/Contacts";
import NotFound from "./pages/404/NotFound";

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="catalog" element={<Catalog />} />
                        <Route path="checkout" element={<Checkout />} />
                        <Route path="feedback" element={<Feedback />} />
                        <Route path="contacts" element={<Contacts />} />
                        <Route path=":slug" element={<Product />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
