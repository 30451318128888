import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "../../components/Container/Container";
import style from "./Feedback.module.css";
import Api from "../../api/api";
import ReCAPTCHA from "react-google-recaptcha";
import { ClipLoader } from "react-spinners";
import { Alert } from "@mui/material";
import { Helmet } from "react-helmet";

const STATUS_PENDING = "pending";

export default function Feedback() {
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

    function onChangeCaptcha(value) {
        setIsCaptchaSuccess(!isCaptchaSuccessful);
    }

    const changeStateResponse = (status) => {
        setResponseCreateOrder(status);
    };

    const [responseSendMail, setResponseCreateOrder] = useState();

    const renderStatusOrder = () => {
        if (responseSendMail === STATUS_PENDING) {
            return <ClipLoader color="#fff" />;
        } else if (typeof responseSendMail === "object") {
            setTimeout(() => {
                changeStateResponse(undefined);
            }, 5000);
            return (
                <Alert security="success">
                    {formValue.name.value}, thank you for placing your order,
                    the manager will contact you shortly
                </Alert>
            );
        } else {
            return (
                <button
                    onClick={(event) => {
                        sendForm(event);
                    }}
                    type="submit"
                    className={`${style.btnSubmit} ${
                        !isCaptchaSuccessful
                            ? `${style.disabled}`
                            : `${style.active}`
                    }`}
                    disabled={!isCaptchaSuccessful ? true : false}
                >
                    {t("buttons.send")}
                </button>
            );
        }
    };

    const [formValue, setFormValue] = useState({
        email: {
            value: "",
            error: false,
        },
        name: {
            value: "",
            error: false,
        },
        phone: {
            value: "",
            error: false,
        },
        comment: {
            value: "",
            error: false,
        },
    });

    const inputChanges = (id, value) => {
        setFormValue({
            ...formValue,
            [id]: {
                value,
                error: value < 1 ? true : false,
            },
        });
    };

    const sendForm = (event) => {
        event.preventDefault();
        const data = {
            contact_name: formValue.name.value,
            contact_email: formValue.email.value,
            contact_phone: formValue.phone.value,
            contact_message: formValue.comment.value,
        };
        Api.sendEmail(data, changeStateResponse);
        changeStateResponse(STATUS_PENDING);
    };

    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <title>Feedback</title>
            </Helmet>
            <section className={style.feedbackSection}>
                <Container>
                    <div className={style.mainInfo}>
                        <div className={style.feedbackForm}>
                            <h1 className={style.title}>
                                {t("feedback.title")}
                            </h1>
                            <form method="post" className={style.form}>
                                <div className={style.input}>
                                    <label htmlFor="email">
                                        {t("feedback.email")}
                                    </label>
                                    <input
                                        type="text"
                                        id="email"
                                        className={
                                            formValue.email.error
                                                ? `${style.errorInput}`
                                                : ""
                                        }
                                        onChange={(event) =>
                                            inputChanges(
                                                "email",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className={style.input}>
                                    <label htmlFor="name">
                                        {t("feedback.name")}
                                    </label>
                                    <input
                                        className={
                                            formValue.name.error
                                                ? `${style.errorInput}`
                                                : ""
                                        }
                                        type="text"
                                        id="name"
                                        onChange={(event) =>
                                            inputChanges(
                                                "name",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className={style.input}>
                                    <label htmlFor="number">
                                        {t("feedback.phone")}
                                    </label>
                                    <input
                                        className={
                                            formValue.phone.error
                                                ? `${style.errorInput}`
                                                : ""
                                        }
                                        type="text"
                                        id="number"
                                        onChange={(event) =>
                                            inputChanges(
                                                "phone",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className={style.input}>
                                    <label htmlFor="comment">
                                        {t("feedback.comment")}
                                    </label>
                                    <textarea
                                        className={
                                            formValue.comment.error
                                                ? `${style.errorInput}`
                                                : ""
                                        }
                                        type="text"
                                        id="comment"
                                        onChange={(event) =>
                                            inputChanges(
                                                "comment",
                                                event.target.value
                                            )
                                        }
                                    />
                                </div>
                                <div className={style.sendBlock}>
                                    {renderStatusOrder()}
                                    <ReCAPTCHA
                                        theme="dark"
                                        size="compact"
                                        sitekey={
                                            process.env
                                                .REACT_APP_RECAPTCHA_SITE_KEY
                                        }
                                        onChange={onChangeCaptcha}
                                        onExpired={onChangeCaptcha}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}
