import React, { useState } from "react";
import style from "./ProductCard.module.css";
import { useDispatch } from "react-redux";
import { addToCart } from "../../store/cartSlice";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Snackbar } from "@mui/material";

export default function ProductCard({
    title,
    discription,
    price,
    imageLink,
    id,
    slug,
}) {
    const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);

    const handleClose = () => {
        setIsOpenSnackBar(false);
    };

    const actionSnackBar = (
        <Link className={style.btnGoToCart} to={"checkout"}>
            Go to cart
        </Link>
    );

    const { t } = useTranslation();

    const productLink = `/${slug}`;

    const dispatch = useDispatch();

    const addCart = () => {
        dispatch(
            addToCart({
                title,
                discription,
                price,
                imageLink,
                id,
                slug,
            })
        );
        setIsOpenSnackBar(true);
    };

    return (
        <>
            <li className={style.productsItem}>
                <Link to={productLink}>
                    <div className={style.productImgBlock}>
                        <img
                            width={248}
                            height={248}
                            src={imageLink}
                            alt={title}
                        />
                    </div>
                    <h3 className={style.productTitle}>{title}</h3>
                </Link>
                <div
                    className={style.descriptionBlock}
                    dangerouslySetInnerHTML={{ __html: discription }}
                ></div>
                <div className={style.productPriceOrAddCart}>
                    <h4>{price} GEL</h4>
                    <button className={style.btnAddCart} onClick={addCart}>
                        {t("card.addButton")}
                    </button>
                </div>
            </li>
            {isOpenSnackBar && (
                <Snackbar
                    message={`${title}, added to cart`}
                    autoHideDuration={6000}
                    open={isOpenSnackBar}
                    onClose={handleClose}
                    action={actionSnackBar}
                />
            )}
        </>
    );
}
