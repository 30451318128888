import React, { useState } from "react";
import style from "./FormCheckout.module.css";

import Container from "../../../../components/Container/Container";
import { useSelector } from "react-redux";
import Api from "../../../../api/api";
import { useTranslation } from "react-i18next";

import ReCAPTCHA from "react-google-recaptcha";
import { ClipLoader } from "react-spinners";
import { Alert } from "@mui/material";

const STATUS_PENDING = "pending";

export default function FormCheckout() {
    const productList = useSelector((state) => state.cart);

    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

    const [errorInput, setErrorInput] = useState(false);

    const [responseCreateOrder, setResponseCreateOrder] = useState();

    const changeStateResponse = (status) => {
        setResponseCreateOrder(status);
    };

    function onChangeCaptcha(value) {
        setIsCaptchaSuccess(!isCaptchaSuccessful);
    }

    const renderStatusOrder = () => {
        if (responseCreateOrder === STATUS_PENDING) {
            return <ClipLoader color="#fff" />;
        } else if (typeof responseCreateOrder === "object") {
            setTimeout(() => {
                changeStateResponse(undefined);
            }, 5000);
            return (
                <Alert security="success">
                    {responseCreateOrder.billing.first_name}, thank you for
                    placing your order, the manager will contact you shortly
                </Alert>
            );
        } else {
            return (
                <button
                    onClick={createOrder}
                    type="submit"
                    className={`${style.checkoutBtn} ${
                        !isCaptchaSuccessful
                            ? `${style.disabled}`
                            : `${style.active}`
                    }`}
                    disabled={!isCaptchaSuccessful ? true : false}
                >
                    {t("checkout.checkoutBtn")}
                </button>
            );
        }
    };

    let totalPrice = 0;

    productList.forEach((item) => {
        totalPrice += item.price * item.quantity;
    });

    const [formData, setFormData] = useState({
        name: "",
        phoneNumber: "",
    });

    const changeInput = (event) => {
        if (event.target.id === "name") {
            setFormData({
                ...formData,
                name: event.target.value,
            });
        } else {
            setFormData({
                ...formData,
                phoneNumber: event.target.value,
            });
        }
    };

    const createOrder = (event) => {
        event.preventDefault();
        if (formData.name.length < 2 || formData.phoneNumber.length < 6) {
            setErrorInput(true);
            return;
        } else {
            setErrorInput(false);
        }
        const data = {
            status: "processing",
            billing: {
                first_name: formData.name,
                phone: formData.phoneNumber,
            },
            line_items: [],
        };
        productList.forEach((item) => {
            data.line_items.push({
                product_id: item.id,
                quantity: item.quantity,
            });
        });
        Api.createOrder(data, changeStateResponse);
        changeStateResponse(STATUS_PENDING);
    };

    const { t } = useTranslation();

    return (
        <section className={style.productsListSection}>
            <Container>
                <div className={style.mainInfo}>
                    <div className={style.checkoutForm}>
                        <form className={style.form}>
                            <div className={style.formInputs}>
                                <div className={style.input}>
                                    <label htmlFor="name">
                                        {t("feedback.name")}
                                    </label>
                                    <input
                                        onChange={changeInput}
                                        type="text"
                                        id="name"
                                        placeholder="Alex"
                                    />
                                </div>
                                <div className={style.input}>
                                    <label htmlFor="number">
                                        {t("feedback.phone")}
                                    </label>
                                    <input
                                        onChange={changeInput}
                                        type="text"
                                        id="number"
                                        placeholder="+995234345964"
                                    />
                                </div>
                                {errorInput && (
                                    <p className={style.errorInput}>
                                        Incorect data
                                    </p>
                                )}
                            </div>
                            <div className={style.formSubmit}>
                                <h3 className={style.checkoutPrice}>
                                    {totalPrice} gel
                                </h3>
                                {renderStatusOrder()}
                            </div>
                            <ReCAPTCHA
                                theme="dark"
                                size="compact"
                                sitekey={
                                    process.env.REACT_APP_RECAPTCHA_SITE_KEY
                                }
                                onChange={onChangeCaptcha}
                                onExpired={onChangeCaptcha}
                            />
                        </form>
                    </div>
                </div>
            </Container>
        </section>
    );
}
