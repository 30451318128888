import React from "react";
import FirstWindow from "./sections/FirstWindow/FirstWindow";
import AboutUs from "./sections/AboutUs/AboutUs";
import Advantages from "./sections/Advantages/Advantages";
import Products from "./sections/Product/Products";
import { Helmet } from "react-helmet";

export default function Home() {
    return (
        <main>
            <Helmet>
                <meta
                    name="description"
                    content="Explore the world of detailing in Georgia with Detailing club. We offer high-quality chemical products for automotive detailing, giving your car a brilliant shine. Learn about our products and services."
                />
                <meta
                    name="keywords"
                    content="Detailing chemicals
            , Professional detailing products
            , Auto detailing in Georgia
            , Chemical products for cars
            , Detailing club"
                />
                <title>
                    Detailing Chemicals in Georgia: Professional Auto Detailing
                    Products - Detailing club
                </title>
            </Helmet>
            <FirstWindow />
            <AboutUs />
            <Products />
            <Advantages />
        </main>
    );
}
