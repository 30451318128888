import React from "react";
import style from "./Advantages.module.css";
import advantagesImage from "../../../../assets/image/advantages.webp";
import { useTranslation } from "react-i18next";
import Container from "../../../../components/Container/Container";

export default function Advantages() {
    const { t } = useTranslation();

    return (
        <section className={style.advantagesSection}>
            <Container>
                <div className={style.mainInfo} id="advantages">
                    <div className={style.advantagesBlock}>
                        <ul className={style.advantagesList}>
                            <h2>{t("advantages.title")}</h2>
                            <li className={style.advantagesItem}>
                                <h3>1</h3>
                                <p>{t("advantages.firstAdv")}</p>
                            </li>
                            <li className={style.advantagesItem}>
                                <h3>2</h3>
                                <p>{t("advantages.secondAdv")}</p>
                            </li>
                            <li className={style.advantagesItem}>
                                <h3>3</h3>
                                <p>{t("advantages.thirdAdv")}</p>
                            </li>
                        </ul>
                        <img src={advantagesImage} alt="advantagesImage" />
                    </div>
                </div>
            </Container>
        </section>
    );
}
