import React from "react";
import style from "./CartItem.module.css";
import { useDispatch } from "react-redux";
import { changeQuantity, deleteProduct } from "../../store/cartSlice";
import { Link } from "react-router-dom";

export default function CartItem({
    imageLink,
    title,
    price,
    quantity,
    id,
    slug,
}) {
    const dispatch = useDispatch();

    const changeQuantityMinus = () => {
        dispatch(
            changeQuantity({
                id,
                quantity: quantity - 1,
            })
        );
    };

    const changeQuantityPlus = () => {
        dispatch(
            changeQuantity({
                id,
                quantity: quantity + 1,
            })
        );
    };

    const deleteItem = () => {
        dispatch(
            deleteProduct({
                id,
            })
        );
    };

    return (
        <li className={style.cartItem}>
            <Link to={`/${slug}`} className={style.titleBlock}>
                <img src={imageLink} alt={title} />
                <h3>{title}</h3>
            </Link>
            <div className={style.priceBlock}>
                <h4>{price} GEL</h4>
                <div className={style.quantityProductBlock}>
                    <button
                        disabled={quantity === 1 ? true : false}
                        className={quantity === 1 ? style.disabledBth : ""}
                        onClick={changeQuantityMinus}
                    >
                        -
                    </button>
                    <span>{quantity}</span>
                    <button
                        disabled={quantity === 99 ? true : false}
                        className={quantity === 99 ? style.disabledBth : ""}
                        onClick={changeQuantityPlus}
                    >
                        +
                    </button>
                </div>
                <h3>{price * quantity} GEL</h3>
                <img
                    className={style.deleteBtn}
                    onClick={deleteItem}
                    src="https://img.icons8.com/material-rounded/24/FF0000/filled-trash.png"
                    alt="delete button"
                />
            </div>
        </li>
    );
}
