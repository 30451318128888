import { createSlice } from "@reduxjs/toolkit";

const cart = createSlice({
    name: "cart",
    initialState: localStorage.getItem("cartItems")
        ? JSON.parse(localStorage.getItem("cartItems"))
        : [],
    reducers: {
        addToCart(state, action) {
            const haveIsProduct = state.find((item) => {
                return item.id === action.payload.id;
            });
            if (!haveIsProduct) {
                state.push({
                    ...action.payload,
                    quantity: 1,
                });
            } else {
                haveIsProduct.quantity++;
            }
            localStorage.setItem("cartItems", JSON.stringify(state));
        },
        changeQuantity(state, action) {
            state.forEach((item) => {
                if (item.id === action.payload.id) {
                    item.quantity = action.payload.quantity;
                    return;
                }
            });
            localStorage.setItem("cartItems", JSON.stringify(state));
        },
        deleteProduct(state, action) {
            const deleteArr = state.filter(
                (item) => item.id !== action.payload.id
            );
            localStorage.setItem("cartItems", JSON.stringify(deleteArr));
            return deleteArr;
        },
    },
});

export const { addToCart, changeQuantity, deleteProduct } = cart.actions;

export default cart.reducer;
