import React from "react";
import style from "./FirstWindow.module.css";
import { Link } from "react-router-dom";
import partnersImage from "../../../../assets/image/partners.webp";

import { useTranslation } from "react-i18next";
import Container from "../../../../components/Container/Container";

export default function FirstWindow() {
    const { t } = useTranslation();

    return (
        <section className={style.firstWindowSection}>
            <Container>
                <div className={style.mainInfo}>
                    <h1>{t("firstWindow.title")}</h1>
                    <p>{t("firstWindow.disctiption")}</p>
                    <div className={style.buttonLinkBlock}>
                        <Link to="/catalog" className="">
                            {t("buttons.catalog")}
                        </Link>
                        <a href="#about" className="">
                            {t("buttons.about")}
                        </a>
                    </div>
                    <img src={partnersImage} alt="partnersImage" />
                </div>
            </Container>
        </section>
    );
}
