import React from "react";
import CheckoutList from "./section/CheckoutList/CheckoutList";
import FormCheckout from "./section/FormCheckout/FormCheckout";
import style from "./Checkout.module.css";
import { Helmet } from "react-helmet";

export default function Checkout() {
    return (
        <div className={style.checkoutContainer}>
            <Helmet>
                <title>Cart</title>
            </Helmet>
            <CheckoutList />
            <FormCheckout />
        </div>
    );
}
