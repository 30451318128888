const API_KEY = "ck_5017bd7af02ddd893c8639e1a70b33a8e0e58b3d";
const API_PASS = "cs_838792ff8231f1cc01488d883169a150875f033f";
const BASE_URL = "https://api.detailingclub.ge";

class Api {
    static getProducts(callback, properties) {
        fetch(
            `${BASE_URL}/wp-json/wc/v3/products?consumer_key=${API_KEY}&consumer_secret=${API_PASS}&${
                properties.limit ? `per_page=${properties.limit}` : ""
            }&lang=${properties.lang}&${
                properties.category ? `category=${properties.category}` : ""
            }&${
                properties.include ? `include=${properties.include.join()}` : ""
            }&${properties.numberPage ? `page=${properties.numberPage}` : ""}`
        )
            .then((response) => response.json())
            .then((json) => callback(json))
            .catch((error) => error);
    }

    static getCategories(callback, lang) {
        fetch(
            `${BASE_URL}/wp-json/wc/v3/products/categories?consumer_key=${API_KEY}&consumer_secret=${API_PASS}&lang=${lang}`
        )
            .then((response) => response.json())
            .then((json) => callback(json))
            .catch((error) => error);
    }

    static getProductBySlug(callback, properties) {
        fetch(
            `${BASE_URL}/wp-json/wc/v3/products?consumer_key=${API_KEY}&consumer_secret=${API_PASS}&lang=${properties.lang}&slug=${properties.slug}`
        )
            .then((response) => response.json())
            .then((json) => callback(json));
    }

    static createOrder(data, callback = (res) => console.log(res)) {
        fetch(
            `${BASE_URL}/wp-json/wc/v3/orders?consumer_key=${API_KEY}&consumer_secret=${API_PASS}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }
        )
            .then((response) => response.json())
            .then((json) => callback(json))
            .catch((error) => error);
    }
    static sendEmail(data, callback = (res) => console.log(res)) {
        fetch(`${BASE_URL}/wp-json/contact/v1/sendMail`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => callback(response))
            .catch((error) => error);
    }
}

export default Api;
