import React, { useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

import style from "./Layout.module.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { slide as Menu } from "react-burger-menu";

function isActiveNavLink({ isActive }) {
    return isActive
        ? `${style.activeNavLink} ${style.navBarItem}`
        : `${style.navBarItem}`;
}

const burgerStyle = {
    bmBurgerButton: {
        position: "fixed",
        width: "36px",
        height: "30px",
        right: "25px",
        top: "24px",
    },
    bmBurgerBars: {
        background: "#ffffff",
        height: "2px",
    },
    bmBurgerBarsHover: {
        background: "#a90000",
    },
    bmCrossButton: {
        height: "34px",
        width: "34px",
    },
    bmCross: {
        background: "#ffffff",
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: "0",
    },
    bmMenu: {
        background: "#000000",
        padding: "2.5em 1.5em 0",
        fontSize: "1.15em",
        overflow: "hidden",
    },
    bmMorphShape: {
        fill: "#373a47",
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em",
        gap: "2em",
        display: "flex",
        flexDirection: "column",
    },
    bmItem: {
        display: "inline-block",
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: "0",
        left: "0",
    },
};

export default function Layout() {
    const { t, i18n } = useTranslation();

    const numberOfProducts = useSelector((state) => state.cart).length;

    const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(null);

    const closeBurgerMenu = () => {
        setBurgerMenuIsOpen(false);
    };

    const handleOnOpen = () => {
        setBurgerMenuIsOpen(true);
    };

    const handleOnClose = () => {
        setBurgerMenuIsOpen(false);
    };

    const [langObj, setLangObj] = useState({
        en: false,
        ru: false,
        ge: false,
        [localStorage.getItem("i18nextLng")]: true,
    });

    const choiceLang = (lang) => {
        setLangObj({
            en: false,
            ru: false,
            ge: false,
            [lang]: true,
        });
        i18n.changeLanguage(`${lang}`);
    };

    return (
        <>
            <header className={`${style.header}`}>
                <div className={style.logo}>
                    <Link to="/">Detailing Club</Link>
                </div>
                <nav className={style.navBar}>
                    <div className={style.navBarList}>
                        <NavLink className={isActiveNavLink} to="/">
                            {t("buttons.home")}
                        </NavLink>
                        <NavLink className={isActiveNavLink} to="/catalog">
                            {t("buttons.catalog")}
                        </NavLink>
                        <NavLink className={isActiveNavLink} to="/feedback">
                            {t("buttons.leave")}
                        </NavLink>
                        <NavLink className={isActiveNavLink} to="/contacts">
                            {t("buttons.contacts")}
                        </NavLink>
                        <div className={style.choiceLang}>
                            {Object.keys(langObj).map((lang) => (
                                <button
                                    key={lang}
                                    className={
                                        langObj[`${lang}`]
                                            ? `${style.choiceLangActive}`
                                            : ""
                                    }
                                    onClick={() => choiceLang(lang)}
                                >
                                    {lang}
                                </button>
                            ))}
                        </div>
                    </div>
                    <Link to="/checkout" alt="cart" className={style.cart}>
                        <img
                            className={style.cartImg}
                            src="https://img.icons8.com/ios/50/ffffff/shopping-bag--v1.png"
                            alt="cart"
                        />
                        <span className={style.numberOfProducts}>
                            {numberOfProducts}
                        </span>
                    </Link>
                </nav>
                <div className={style.burgerNavBar}>
                    <Link to="/checkout" alt="cart" className={style.cart}>
                        <img
                            className={style.cartImg}
                            src="https://img.icons8.com/ios/50/ffffff/shopping-bag--v1.png"
                            alt="cart"
                        />
                        <span className={style.numberOfProducts}>
                            {numberOfProducts}
                        </span>
                    </Link>
                    <div className={style.burgerMenuBlock}>
                        <Menu
                            onClose={handleOnClose}
                            onOpen={handleOnOpen}
                            isOpen={burgerMenuIsOpen}
                            disableAutoFocus
                            right
                            styles={burgerStyle}
                        >
                            <NavLink
                                onClick={closeBurgerMenu}
                                className={style.navBarItem}
                                to="/"
                            >
                                {t("buttons.home")}
                            </NavLink>
                            <NavLink
                                onClick={closeBurgerMenu}
                                className={style.navBarItem}
                                to="/catalog"
                            >
                                {t("buttons.catalog")}
                            </NavLink>
                            <NavLink
                                onClick={closeBurgerMenu}
                                className={style.navBarItem}
                                to="/feedback"
                            >
                                {t("buttons.leave")}
                            </NavLink>
                            <NavLink
                                onClick={closeBurgerMenu}
                                className={style.navBarItem}
                                to="/contacts"
                            >
                                {t("buttons.contacts")}
                            </NavLink>
                            <div className={style.choiceLang}>
                                {Object.keys(langObj).map((lang) => (
                                    <button
                                        key={lang}
                                        className={
                                            langObj[`${lang}`]
                                                ? `${style.choiceLangActive}`
                                                : ""
                                        }
                                        onClick={() => {
                                            choiceLang(lang);
                                            closeBurgerMenu();
                                        }}
                                    >
                                        {lang}
                                    </button>
                                ))}
                            </div>
                        </Menu>
                    </div>
                </div>
            </header>
            <Outlet />
            <footer className={style.footer}>
                <div className={style.aboutFooter}>
                    <h3>DetailigClub</h3>
                    <p>{t("footer.aboutDiscription")}</p>
                </div>
                <div className={style.contacts}>
                    <h3>{t("footer.contactsTitle")}</h3>
                    <a href="tel:+995599221206">+995 599 221 206 (Levan)</a>
                    <a href="tel:+995574155989">+995 574 155 989 (Ani)</a>
                    <a href="mailto:Detailingclubgeorgia@gmail.com">
                        Detailingclubgeorgia@gmail.com
                    </a>
                    <a
                        href="https://maps.app.goo.gl/cfWG6MWR3RBekGRZ9"
                        target="blank"
                    >
                        {t("footer.contactsAddress")}
                    </a>
                </div>
                <div className={style.socialMediaFooter}>
                    <h3>{t("footer.mediaTitle")}</h3>
                    <div className={style.socialMediaFooterList}>
                        <a
                            target="blank"
                            href="https://instagram.com/detailingclubgeorgia?igshid=OGQ5ZDc2ODk2ZA=="
                        >
                            <img
                                className={style.socialImg}
                                src="https://img.icons8.com/ios/50/ffffff/instagram-new--v1.png"
                                alt="instagram"
                            />
                        </a>
                        <a
                            target="blank"
                            href="https://www.facebook.com/GRASSPartner"
                        >
                            <img
                                className={style.socialImg}
                                src="https://img.icons8.com/ios/50/ffffff/facebook--v1.png"
                                alt="facebook"
                            />
                        </a>
                    </div>
                </div>
            </footer>
        </>
    );
}
