import style from "./Contacts.module.css";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Contacts() {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>
                    Contact Us - Get in Touch with Detailing club for Detailing
                    Chemicals in Georgia
                </title>
                <meta
                    name="keywords"
                    content="Contact Detailing club, Detailing chemicals contact, Get in touch with us, Contact information, Georgia detailing chemicals"
                />
                <meta
                    name="description"
                    content="Have questions or need assistance with detailing chemicals in Georgia? Contact Detailing club today. We're here to help you with your automotive detailing needs."
                />
            </Helmet>
            <section className={style.feedbackSection}>
                <Container>
                    <div className={style.mainInfo}>
                        <div className={style.contactsBlock}>
                            <iframe
                                key={"tbilisi"}
                                title="Тбилиси, Агладзе 32."
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3A1fe0a85bff457c920a1916de29f161e289487ec2a2b5658076b345c24b0cad6c&amp;source=constructor"
                                width="600"
                                height="450"
                                style={{ border: "0" }}
                                loading="lazy"
                            ></iframe>
                            <div className={style.contactsInfo}>
                                <h1>{t("contacts.title")}</h1>
                                <div className={style.addressBlock}>
                                    <h3>{t("contacts.addressTitle")}:</h3>
                                    <ol>
                                        <li>
                                            <p>{t("contacts.addressFirst")}</p>
                                        </li>
                                        <li>
                                            <p>{t("contacts.addressSecond")}</p>
                                        </li>
                                        <li>
                                            <p>{t("contacts.addressThird")}</p>
                                        </li>
                                    </ol>
                                </div>
                                <div className={style.numberBlock}>
                                    <h3>{t("contacts.contactsNumber")}:</h3>
                                    <a href="tel:+995599221206">
                                        +995 599 221 206 (Levan)
                                    </a>
                                    <br />
                                    <a href="tel:+995574155989">
                                        +995 574 155 989 (Ani)
                                    </a>
                                </div>
                                <div className={style.numberBlock}>
                                    <h3>{t("contacts.contactsEmail")}:</h3>
                                    <a href="mailto:Detailingclubgeorgia@gmail.com">
                                        Detailingclubgeorgia@gmail.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}
