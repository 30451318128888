import React from "react";
import Container from "../../components/Container/Container";
import style from "./NotFound.module.css";
import { Helmet } from "react-helmet";

export default function NotFound() {
    return (
        <>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <section className={style.feedbackSection}>
                <Container>
                    <div className={style.mainInfo}>
                        <div className={style.notFoundBlock}>
                            <h2>404</h2>
                            <h3>This page does not exist</h3>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
}
