import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import style from "./Product.module.css";
import Container from "../../components/Container/Container";
import Api from "../../api/api";
import { useDispatch } from "react-redux";
import { addToCart } from "../../store/cartSlice";
import { useTranslation } from "react-i18next";
import { ClipLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import { Snackbar } from "@mui/material";

export default function Product() {
    const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);

    const navigate = useNavigate();

    const handleClose = () => {
        setIsOpenSnackBar(false);
    };

    const actionSnackBar = (
        <Link className={style.btnGoToCart} to={"checkout"}>
            Go to cart
        </Link>
    );

    const slugProduct = useParams();

    const [product, setProduct] = useState(null);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const getProduct = (response) => {
            if (response.length === 0) {
                navigate("/catalog");
            } else {
                setProduct(response);
            }
        };
        const properties = {
            lang: i18n.language,
            slug: slugProduct.slug,
        };
        Api.getProductBySlug(getProduct, properties);
    }, [i18n.language, navigate, slugProduct.slug]);

    const dispatch = useDispatch();

    const addCart = () => {
        dispatch(
            addToCart({
                title: product[0].name,
                discription: product[0].description,
                price: product[0].price,
                imageLink: product[0].images[0].src,
                id: product[0].id,
                slug: product[0].slug,
            })
        );
        setIsOpenSnackBar(true);
    };

    return (
        <>
            <Helmet>
                <title>{product && product[0].name}</title>
                <meta
                    name="description"
                    content={product && product[0].description}
                />
            </Helmet>
            <section className={style.productSection}>
                <Container>
                    <div className={style.mainInfo}>
                        {product === null ? (
                            <div className={style.clipLoaderBlock}>
                                <ClipLoader color="#fff" />
                            </div>
                        ) : (
                            <div>
                                <div className={style.productBlock}>
                                    <div className={style.imgBlock}>
                                        <img
                                            src={product[0].images[0].src}
                                            alt={product[0].name}
                                        />
                                    </div>
                                    <div className={style.productInfo}>
                                        <h1>{product[0].name}</h1>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: product[0]
                                                    .short_description,
                                            }}
                                        ></div>
                                        <h3>
                                            <span>
                                                {t("singleProduct.price")}:
                                            </span>
                                            <br />
                                            {product[0].price} GEL
                                        </h3>
                                        <button onClick={addCart}>
                                            {t("card.addButton")}
                                        </button>
                                        <h5>
                                            {t("singleProduct.category")}:&nbsp;
                                            <span>
                                                {product[0].categories[0].name}
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div className={style.description}>
                                    <h2>{t("singleProduct.description")}:</h2>
                                    <span></span>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: product[0].description,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        )}
                    </div>
                </Container>
            </section>
            {isOpenSnackBar && (
                <Snackbar
                    message={`${product[0].name}, ${t("pushAddedToCart")}`}
                    autoHideDuration={6000}
                    open={isOpenSnackBar}
                    onClose={handleClose}
                    action={actionSnackBar}
                />
            )}
        </>
    );
}
