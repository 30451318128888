import React, { useEffect, useState } from "react";
import style from "./Products.module.css";
import { Link } from "react-router-dom";
import ProductCard from "../../../../components/ProductCard/ProductCard";
import Api from "../../../../api/api";
import Container from "../../../../components/Container/Container";
import { useTranslation } from "react-i18next";

export default function Products() {
    const [state, setState] = useState(null);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        const properties = {
            lang: i18n.language,
            limit: 4,
        };
        Api.getProducts(setState, properties);
    }, [i18n.language]);

    return (
        <section className={style.firstWindowSection}>
            <Container>
                <div className={style.mainInfo}>
                    <div className={style.headerProductsBlock}>
                        <h2>{t("products.title")}</h2>
                        <Link to="/catalog">{t("products.button")}</Link>
                    </div>
                    <ul className={style.productsList}>
                        {state &&
                            state.map((item) => {
                                return (
                                    <ProductCard
                                        key={item.id}
                                        title={item.name}
                                        price={item.price}
                                        discription={item.short_description}
                                        imageLink={item.images[0].src}
                                        id={item.id}
                                        slug={item.slug}
                                    />
                                );
                            })}
                    </ul>
                </div>
            </Container>
        </section>
    );
}
