import React from "react";
import style from "./AboutUs.module.css";
import aboutUsImage from "../../../../assets/image/about-us.webp";
import { useTranslation } from "react-i18next";

import Container from "../../../../components/Container/Container";

export default function AboutUs() {
    const { t } = useTranslation();

    return (
        <section className={style.aboutUsSection}>
            <Container>
                <div className={style.mainInfo} id="about">
                    <img src={aboutUsImage} alt="aboutUs" />
                    <div className={style.infoBlock}>
                        <h3>{t("aboutUs.name")}</h3>
                        <h2>{t("aboutUs.title")}</h2>
                        <p>{t("aboutUs.disctiption")}</p>
                        <a href="#advantages" className={style.readMoreLink}>
                            {t("buttons.read")}
                        </a>
                    </div>
                </div>
            </Container>
        </section>
    );
}
