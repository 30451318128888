import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./ProductsList.module.css";
import ProductCard from "../../../../components/ProductCard/ProductCard";

import Container from "../../../../components/Container/Container";
import Api from "../../../../api/api";
import { ClipLoader } from "react-spinners";

export default function ProductsList({ category, numberPage }) {
    const [products, setProducts] = useState(null);
    const { i18n } = useTranslation();

    useEffect(() => {
        setProducts(null);
        const properties = {
            lang: i18n.language,
            category,
            numberPage,
            limit: 12,
        };
        Api.getProducts(setProducts, properties);
    }, [i18n.language, category, numberPage]);

    return (
        <>
            <section className={style.productsListSection}>
                <Container>
                    <div className={style.mainInfo}>
                        <ul className={style.productsList}>
                            {products ? (
                                products.map((item) => {
                                    return (
                                        <ProductCard
                                            key={item.id}
                                            title={item.name}
                                            discription={item.short_description}
                                            price={item.price}
                                            imageLink={item.images[0].src}
                                            id={item.id}
                                            slug={item.slug}
                                        />
                                    );
                                })
                            ) : (
                                <ClipLoader
                                    color="#fff"
                                    className={style.loader}
                                />
                            )}
                        </ul>
                    </div>
                </Container>
            </section>
        </>
    );
}
