import style from "./ChekoutList.module.css";

import Container from "../../../../components/Container/Container";
import { useSelector } from "react-redux";
import CartItem from "../../../../components/CartItem/CartItem";
import { useTranslation } from "react-i18next";

export default function CheckoutList() {
    const { t } = useTranslation();

    const listproducts = useSelector((state) => state.cart);

    return (
        <section className={style.productsListSection}>
            <Container>
                <div className={style.mainInfo}>
                    <div className={style.checkoutList}>
                        <div className={style.checkoutTitle}>
                            <h1>{t("checkout.title")}</h1>
                        </div>
                        <ul className={style.checkoutListProducts}>
                            {listproducts &&
                                listproducts.map((item) => {
                                    return (
                                        <CartItem
                                            key={item.id}
                                            id={item.id}
                                            price={item.price}
                                            imageLink={item.imageLink}
                                            title={item.title}
                                            quantity={item.quantity}
                                            slug={item.slug}
                                        />
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            </Container>
        </section>
    );
}
